import Cookies from "js-cookie";
import {CookieConstants} from "../constants/CookieConstants";
import {useNavigate} from "react-router-dom";
import {useCookies} from "react-cookie";

const removeCookies = () => {
  if (Cookies.get(CookieConstants.token)) {
    Cookies.remove(CookieConstants.token);
  }
  if (Cookies.get(CookieConstants.refreshToken)) {
    Cookies.remove(CookieConstants.refreshToken);
  }
}

export default function useLogoutActions() {
  const navigate = useNavigate();
  const [cookies, setCookie, removeCookie] = useCookies(['token', 'refreshToken']);

  return {
    logout() {
      removeCookie('token');
      removeCookie('refreshToken');

      navigate('/login');
    }
  }
}