import {login, refreshToken} from "../api/LoginApi";
import Cookies from 'js-cookie';
import {CookieConstants} from "../constants/CookieConstants";

export const loginAction = async (context, payload) => {
  return await login(payload).then(json => {
    handleLogin(context, json);

    return json
  }).catch(error => {
    return Promise.reject("Invalid username or password")
  });
};

export const handleLogin = (context, json) => {
  Cookies.set(CookieConstants.token, json.accessToken);
  Cookies.set(CookieConstants.refreshToken, json.refreshToken, {expires: 7});

  context.update({ token: json.accessToken });
}
