
const ItemUtils = {
  removeItem(list, item) {
    return [...list.filter(it => it.id !== item.id)];
  },
  replaceItem(list, value) {
    return [...list.map(item => item.id === value.id ? value : item)];
  }
}

export default ItemUtils;