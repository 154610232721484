import {
  Box,
  Card,
  CardContent,
  CardHeader,
  Collapse, Divider,
  List,
  ListItemButton, ListItemIcon, ListItemText,
  MenuItem,
  Select,
  Stack, Typography
} from "@mui/material";
import React from "react";
import {
  GetActiveAndFutureEnrolledStudents,
  GetClassesStatusesForStudent,
} from "../../api/EnrollmentApi";
import {ErrorHandler} from "../../utils/ErrorHandler";
import LoadingSpinner from "../LoadingSpinner";
import {CheckCircle, ExpandLess, ExpandMore, OpenInNew, Warning} from "@mui/icons-material";
import { format } from "date-fns";


const AppWidgetClassesStatus = ({  }) => {
  const [items, setItems] = React.useState([]);
  const [selectedItem, setSelectedItem] = React.useState(null);
  const [statuses, setStatuses] = React.useState([]);
  const [loading, setLoading] = React.useState(false);

  React.useEffect(() => {
    GetActiveAndFutureEnrolledStudents().then(json => {
      setItems(json);
    }).catch(error => ErrorHandler.enqueueError(error));
  }, []);

  React.useEffect(() => {
    if (items.length > 0) {
      setSelectedItem(items[0].id);
    }
  }, [items]);

  React.useEffect(() => {
    if (selectedItem && !loading) {
      setLoading(true);
      GetClassesStatusesForStudent(selectedItem).then(json => {
        setStatuses(json)

      })
      .catch(error => ErrorHandler.enqueueError(error))
      .finally(() => setLoading(false));
    }
  }, [selectedItem, setLoading]);

  const handleStudentChange = (event) => {
    setSelectedItem(event.target.value);
  }

  const handleOpen = () => {
    window.open(`/enrollments/students/${selectedItem}/events`, '_blank')
  }

  console.log(statuses);

  return  (
      <Card>
        <CardHeader title={"Class Status"} />
        <CardContent sx={{ pt: 1 }}>
          <Stack spacing={1}>
            <Select value={selectedItem} onChange={handleStudentChange} size="small">
              {items.map((item) => (
                  <MenuItem key={item.id} value={item.id}>
                    {item.fullName}
                  </MenuItem>
              ))}
            </Select>
            { !loading && statuses.length > 0 ? (
                <List>
                  {statuses.map((status) => (
                      <ClassStatus key={status.id} status={status} />
                  ))}
                </List>
            ) : (
                <LoadingSpinner />
            )}
          </Stack>
        </CardContent>
      </Card>
  )
}

const ClassStatus = ({status}) => {
  const [open, setOpen] = React.useState(false);

  const handleClick = () => setOpen(!open);

  console.log(status.classes?.name);

  return (
        <Stack>
          <ListItemButton onClick={handleClick} sx={{ borderBottom: "1px" }}>
            <ListItemIcon>{status.onTrack ? <CheckCircle color="success" /> : <Warning color="warning" />}</ListItemIcon>
            <ListItemText>{status.classes?.name}  - ({ status.onTrack ? `${Math.abs(status.numDaysBehind)} days ahead` : `${status.numDaysBehind} days behind`})</ListItemText>
            <ListItemIcon sx={{ minWidth: 20 }}>{open ? <ExpandLess /> : <ExpandMore />}</ListItemIcon>
          </ListItemButton>
          <Collapse key={status.id} in={open}>
            <Stack sx={{ pl: 3, py: 1 }}>
              <Stack direction="row">
                <Typography width={120} variant="subtitle2">Days Left: </Typography>
                <Box>{status.numDaysLeft}</Box>
              </Stack>
              <Stack direction="row">
                <Typography width={120} variant="subtitle2">Class End Date: </Typography>
                <Box>{formatDate(status.classes?.endDate)}</Box>
              </Stack>
              <Stack direction="row">
                <Typography width={120} variant="subtitle2">Actual End Date: </Typography>
                <Box>{formatDate(status.lastDate)}</Box>
              </Stack>
            </Stack>
          </Collapse>
          <Divider />
        </Stack>
  )
}

function formatDate(dateString) {
  const date = new Date(dateString);
  const day = date.getDate();
  const month = format(date, 'MMMM');
  const year = date.getFullYear();

  return `${month} ${day}, ${year}`;
}

export default AppWidgetClassesStatus;