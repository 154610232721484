
export const GetEnrollments = () => {
  return fetchApi("/enrollments/")
}

export const GetActiveEnrolledStudents = () => {
  return fetchApi("/enrollments/students")
}

export const GetActiveAndFutureEnrolledStudents = () => {
  return fetchApi("/enrollments/students/current-and-future")
}

export const GetClassesStatusesForStudent = (id) => {
  return fetchApi(`/enrollments/students/${id}/status`)
}

export const GetEnrollmentEvents = (id) => {
  return fetchApi(`/enrollments/${id}/events`)
}

export const GetEnrollmentEventsForStudent = (id) => {
  return fetchApi(`/enrollments/students/${id}/events`)
}

export const CreateEnrollment = (subject) => {
  return fetchApi('/enrollments/', {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json'
    },
    body: JSON.stringify(subject)
  });
}

export const UpdateEnrollment = (id, subject) => {
  return fetchApi(`/enrollments/${id}`, {
    method: 'PUT',
    headers: {
      'Content-Type': 'application/json'
    },
    body: JSON.stringify(subject)
  });
}

export const DeleteEnrollment = (id) => {
  return fetchApi(`/enrollments/${id}`, { method: 'DELETE' })
}