import * as PropTypes from "prop-types";
import {Box, Container} from "@mui/material";
import Typography from "../theme/overrides/Typography";
import Button from "../theme/overrides/Button";
import { styled } from '@mui/material/styles';
import {Link as RouterLink} from 'react-router-dom';
import Page from "../components/Page";

const ContentStyle = styled('div')(({ theme }) => ({
  maxWidth: 480,
  margin: 'auto',
  minHeight: '100vh',
  display: 'flex',
  justifyContent: 'center',
  flexDirection: 'column',
  padding: theme.spacing(12, 0)
}));

// ----------------------------------------------------------------------

Page.propTypes = {
  title: PropTypes.string,
  children: PropTypes.node
};
export default function NotFoundPage() {
  return (
      <Page title="404 Page Not Found">
        <Container>
          <ContentStyle sx={{ textAlign: 'center', alignItems: 'center' }}>
            <Typography variant="h3" paragraph>
              Sorry, page not found!
            </Typography>

            <Typography sx={{ color: 'text.secondary' }}>
              Sorry, we couldn’t find the page you’re looking for. Perhaps you’ve mistyped the URL? Be
              sure to check your spelling.
            </Typography>

            <Box
                component="img"
                src="/static/illustrations/illustration_404.svg"
                sx={{ height: 260, mx: 'auto', my: { xs: 5, sm: 10 } }}
            />

            <Button to="/" size="large" variant="contained" component={RouterLink}>
              Go to Home
            </Button>
          </ContentStyle>
        </Container>
      </Page>
  );
}