import {SERVER} from "../constants/ServiceConstants";

export const getBaseUrl = () => {
  if (process.env.NODE_ENV !== 'production') {
    return `//${SERVER.host}:8080/api`
  }

  return `/api`
}

export const applyAuthHeader = (headers, token) => {
  const authHeader = `Bearer ${token}`;
  if (headers) {
    return {...headers, 'authorization': authHeader}
  } else {
    return { 'authorization': authHeader }
  }
};