import React from 'react';
import PropTypes from 'prop-types';
import { useEffect } from 'react';
import { Link as RouterLink, useLocation } from 'react-router-dom';
// material
import { styled } from '@mui/material/styles';
import { Box, Link, Button, Drawer, Typography, Avatar, Stack } from '@mui/material';
// mock
import account from '../_mock/account';
// hooks
import useResponsive from '../hooks/useResponsive';
// components
import Logo from '../components/Logo';
import Scrollbar from '../components/Scrollbar';
import NavSection from '../components/NavSection';
//
import navConfig from './NavConfig';
import {GlobalContext} from "../providers/GlobalProvider";

// ----------------------------------------------------------------------

const DRAWER_WIDTH = 280;

const RootStyle = styled('div')(({ theme }) => ({
  [theme.breakpoints.up('lg')]: {
    flexShrink: 0,
    width: DRAWER_WIDTH,
  },
}));

const AccountStyle = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  padding: theme.spacing(2, 2.5),
  borderRadius: Number(theme.shape.borderRadius) * 1.5,
  backgroundColor: theme.palette.grey[500_12],
}));

// ----------------------------------------------------------------------

Sidebar.propTypes = {
  isOpenSidebar: PropTypes.bool,
  onCloseSidebar: PropTypes.func,
};

export default function Sidebar({ isOpenSidebar, onCloseSidebar }) {
  const { pathname } = useLocation();
  const context = React.useContext(GlobalContext);

  const isDesktop = useResponsive('up', 'lg');

  useEffect(() => {
    if (isOpenSidebar) {
      onCloseSidebar();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pathname]);

  const renderContent = (
      <Scrollbar
          sx={{
            height: 1,
            '& .simplebar-content': { height: 1, display: 'flex', flexDirection: 'column' },
          }}
      >
        <Box sx={{ px: 2.5, py: 3, display: 'inline-flex' }}>
          <Logo />
        </Box>

        { context.user && (
            <Box sx={{ mb: 5, mx: 2.5 }}>
              <Link underline="none" component={RouterLink} to="#">
                <AccountStyle>
                  {/*<Avatar src={account.photoURL} alt="photoURL" />*/}
                  <Box sx={{ ml: 2 }}>
                    <Typography variant="subtitle2" sx={{ color: 'text.primary' }}>
                      {context.user.firstName} {context.user.lastName}
                    </Typography>
                    {/*<Typography variant="body2" sx={{ color: 'text.secondary' }}>
                      {account.role}
                    </Typography>*/}
                  </Box>
                </AccountStyle>
              </Link>
            </Box>
        )}

        <NavSection navConfig={navConfig} />

        <Box sx={{ flexGrow: 1 }} />

        <Box sx={{ px: 2.5, pb: 3, mt: 10 }}>

        </Box>
      </Scrollbar>
  );

  return (
      <RootStyle>
        {!isDesktop && (
            <Drawer
                open={isOpenSidebar}
                onClose={onCloseSidebar}
                PaperProps={{
                  sx: { width: DRAWER_WIDTH },
                }}
            >
              {renderContent}
            </Drawer>
        )}

        {isDesktop && (
            <Drawer
                open
                variant="persistent"
                PaperProps={{
                  sx: {
                    width: DRAWER_WIDTH,
                    bgcolor: 'background.default',
                    borderRightStyle: 'dashed',
                  },
                }}
            >
              {renderContent}
            </Drawer>
        )}
      </RootStyle>
  );
}