// scroll bar
import 'simplebar/src/simplebar.css';

import {render} from 'react-dom';
import { BrowserRouter } from 'react-router-dom';
import { HelmetProvider } from 'react-helmet-async';

//
import App from './App';
import reportWebVitals from './reportWebVitals';
import GlobalProvider from "./providers/GlobalProvider";
import {CookiesProvider} from "react-cookie";
import {SnackbarProvider} from "notistack";
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';

// ----------------------------------------------------------------------

const root = document.getElementById('root');

render(
    <CookiesProvider>
      <SnackbarProvider maxSnack={3}>
        <LocalizationProvider dateAdapter={AdapterDateFns}>
          <GlobalProvider>
            <HelmetProvider>
              <BrowserRouter>
                <App />
              </BrowserRouter>
            </HelmetProvider>
          </GlobalProvider>
        </LocalizationProvider>
      </SnackbarProvider>
    </CookiesProvider>,
    root
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();